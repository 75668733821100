import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";

import { FormGrid, FormRow, FormElement, FormMessage } from "./form-elements";
import { Button, InternalLinkButton, InternalLinkButtonWhite } from "./button-elements";
import { Block, BlockSeparator, BlockTitle, BlockParagraph } from "./block-elements";
import { PageWrapper } from "./wrapper-elements";
import TextBox from "./text-box";
import theme from "./theme";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const OutputOuterWrapper = styled.div`
  position: relative;
  width: 800px;
  height: 800px;
  display: flex;
  margin-top: 48px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  background-color: ${theme.color.background};
  border: 1.5px solid ${theme.color.background};
  overflow: hidden;
  // cursor: pointer;
  // &:hover {
  //   border: 1.5px solid ${theme.color.line_dark};
  // }
  // &:focus {
  //   border: 1.5px solid ${theme.color.line_dark};
  // }
`;

const OutputWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: -32px;
  left: 0;
  right: -32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: content-box;
  overflow: scroll;
`;

const Output = styled.pre`
  box-sizing: border-box;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 48px;
  padding-right 48px;
  line-height: 1.5;
  color: ${theme.color.text};
`;

const Document = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 96px;
`;

const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 350;
  padding-bottom: 24px;
  line-height: 1.5;
  width: 800px;
  color: ${theme.color.text};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Citation = styled.div`
  font-size: 12px;
  font-weight: 350;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
  color: ${theme.color.text};
  border: 1px solid ${theme.color.text};
  border-radius: 50px;
  margin-top: 8px;
`;

const Division = styled.span`
  padding-right: 12px;
  font-size: 16px;
  font-weight: 600;
`;

export default function App() {

  const default_query = '/databases/text-corpus/eu-law/secondary-law?document_id=DIR-1996-0009-19960311&recitals=0'
  const default_api_key = 'giTlq-ENopH-1tuch-uCoPI-SwuSp'

  const [query, setQuery] = React.useState(default_query);
  const [key, setKey] = React.useState(default_api_key);
  const [data, setData] = React.useState(undefined);

  const getData = () => {
    Axios.get("http://localhost:4000" + query)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const labelsToExclude = ['Paragraph', 'Point', 'Unnumbered paragraph', 'Unnumbered recital', 'Document title']

  const cleanDivisionLabel = (label) => {
    if (labelsToExclude.includes(label)) {
      label = ''
    }
    return label
  }

  let output;
  let text;
  if (data) {
    output =
      <Output>
        {JSON.stringify(data, null, 2)}
      </Output>

    text =
      <Block backgroundColor='white'>
        <Document>
          <BlockTitle>
            Document
          </BlockTitle>
          {data.map((item, i) => {
            return (
              <>
                <Paragraph key={i} style={{ paddingLeft: (item.line.division.depth - 1) * 24 }}>
                  <Column>
                    <span>
                      {item.line.division.value.string == '' ? null : <Division>{cleanDivisionLabel(item.line.division.label)} {item.line.division.value.string}</Division>}
                      {item.text}
                    </span>
                    {item.line.citation.length > 0 ? <Citation>
                      {item.line.citation.join(', ')}
                    </Citation> : null}
                  </Column>
                </Paragraph>
              </>
            )
          })}
        </Document>
      </Block>
  }

  return (
    <PageWrapper>
      <Block backgroundColor='white'>
        <InputWrapper>
          <FormElement>
            <TextBox
              value={query}
              onChange={setQuery}
              placeholder=""
              type="text"
              label="API query"
              width="800px"
            />
          </FormElement>
          <FormElement>
            <TextBox
              value={key}
              onChange={setKey}
              placeholder=""
              type="text"
              label="API key"
              width="800px"
            />
          </FormElement>
          <FormElement>
            <Button primary onClick={() => getData()}>
              Run query
            </Button>
          </FormElement>
        </InputWrapper>
        <OutputOuterWrapper>
          <OutputWrapper>
            {output}
          </OutputWrapper>
        </OutputOuterWrapper>
      </Block>
      {text}
    </PageWrapper>
  );
};
